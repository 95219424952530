import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent";
import Header from "./header"
import Footer from "./footer"
import "../css/reset.css"
import "../css/main.scss"

const Layout = ({ children, additionalClass, pageName }) => (
	
	<StaticQuery
		query={graphql`
			query SiteTitleQuery {
				site {
					siteMetadata {
						title
					}
				}
	  		}
		`}
		render={data => (
			
			<>
			<div className="overlay"></div>
			<Header siteTitle={data.site.siteMetadata.title} pageName={pageName}/>
				<main className={additionalClass} id="page-wrap">
					{children}
				</main>
				<CookieConsent disableStyles={true} buttonText="OK, got it!">
					<h3>Cookie Usage and Settings</h3>
					<p>Accessing our website tells us you are happy to receive cookies. However you can change your cookie settings at any time.</p>
				</CookieConsent>
			<Footer/>
			</>
		)}
	/>
)

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export const fluidImage = graphql`

fragment fluidImage on File {
	childImageSharp {
		fluid(maxWidth: 3000) {
			...GatsbyImageSharpFluid
		}
	}
}

`

if (typeof window !== "undefined") {
	// eslint-disable-next-line global-require
	require("smooth-scroll")('a[href*="#"]', {speed: 1000, speedAsDuration: true, updateURL: false})
}

export default Layout