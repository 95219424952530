import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import { ReactComponent as Logo } from '../images/logo_new.svg';

class Header extends React.Component {

	componentDidMount = () => {

		if (document.querySelector('.mobile-toggle')) {

			const el = document.querySelector('.mobile-toggle');
			el.onclick = function() {
				el.classList.toggle('open');
				document.querySelector('.menu').classList.toggle('open');
				document.querySelector('.pageName').classList.toggle('open');
				document.querySelector('.overlay').classList.toggle('show');
				document.querySelector('#header').classList.toggle('show');
			}
		
		}

		document.addEventListener('click', function (event) {
			if (!event.target.matches('.menu li a')) return;
			event.preventDefault();
			document.querySelector('.overlay').classList.remove('show');
			document.querySelector('#header').classList.remove('show');
			document.querySelector('.mobile-toggle').classList.remove('open');
			document.querySelector('.menu').classList.remove('open');

		}, false);
		

	}

	render() {
		
		const pageClassName = this.props.pageName.replace(/ /g,'').toLowerCase();

		return (
			
			<StaticQuery

			query={graphql`
				query headerQuery {
					allWordpressWpApiMenusMenusItems {
						edges {
							node {
								items {
									url
									title
									object_slug
								}
							}
						}
					}
				}
			`}

			render={data => (
				
				<>
					<header id="header" className={pageClassName}>
						<div className="inner">
								<Link to="/" >
									<Logo className="logo"/>
								</Link>
								<div className="mobile-toggle">
									<span></span>
									<span></span>
									<span></span>
									<span></span>
								</div>
								<span className="pageName">{this.props.pageName}</span>
								<ul className="menu">
									<li><Link activeClassName="active" to="#">Home</Link></li>
									<li><Link activeClassName="active" to="/about">About</Link></li>
									<li><Link activeClassName="active" to="/#work">Work</Link></li>
									<li><Link activeClassName="active" to="/#contact">Contact</Link></li>
								</ul>
						</div>
					</header>
				</>
			)}
			/>
	  )
	}
}

if(typeof window !== 'undefined'){
	window.onscroll = function() {
		scrollFunction();
	}
};

function scrollFunction() {

	if (document.getElementById("header")) {

		if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
			document.getElementById("header").classList.add('scroll');
		}

		else {
			document.getElementById("header").classList.remove('scroll');
		}
	}

}

export default Header