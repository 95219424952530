import React from 'react';
import { StaticQuery, graphql, Link } from "gatsby"

class Footer extends React.Component {

	render() {

		return (
			
			<StaticQuery
			query={graphql`
				query footerQuery {
					wordpressAcfOptions {
						options {
							address
							email_address
							phone_number
							instagram
							linkedin
						}
					}
				}
			`}

			render={data => (
				<footer>
					<div id="contact" className="contact-us">
						<h2 id="contact">Contact us</h2>
						<a href="https://www.google.com/maps/place/Hotcake+Marketing/@51.4855128,-0.6079211,18z/data=!3m1!4b1!4m5!3m4!1s0x48767ae4748ad539:0x59a86a282e12cd17!8m2!3d51.4855111!4d-0.6068268" target="_blank">
							<iframe className="map-iframe" src="https://snazzymaps.com/embed/209316" width="100%" height="600px"></iframe>
						</a>
					</div>
					<div className="information">
						<div className="inner">
							<div className="left">
								Follow us
								<a href={data.wordpressAcfOptions.options.linkedin} target="_blank" rel="noopener noreferrer" className="linkedin">
									<span className="icon-linkedin"></span>
								</a>
								<a href={data.wordpressAcfOptions.options.instagram} target="_blank" rel="noopener noreferrer" className="instagram">
									<span className="icon-instagram"></span>
								</a>
							</div>
							<div className="center">
								<div className="info phone">
									<div className="text">Phone</div>
									<div className="details">
										{data.wordpressAcfOptions.options.phone_number}
									</div>
								</div>
								<div className="info address">
									<div className="text">Address</div>
									<div className="details">
										{data.wordpressAcfOptions.options.address}
									</div>
								</div>
								<div className="info enquiry">
									<div className="text">Enquiry</div>
									<div className="details">
										<a href={`mailto:${data.wordpressAcfOptions.options.email_address}`}>{data.wordpressAcfOptions.options.email_address}</a>
									</div>
								</div>
							</div>
							<div className="right">
								<Link to="/privacy-policy/">Privacy Policy</Link> | &copy; 2020 Hotcake
							</div>
						</div>
					</div>
				</footer>
			)}
			/>
	  )
	}
}

export default Footer