import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ lang, title, image, pathname, description }) {
	const { site } = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						title
						siteURL
						description
					}
				}
			}
		`
	)

	const cleanTitle = title.replace('#038;', '').replace(/<[^>]*>?/gm, '');

	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			titleTemplate={title}
		>
		<meta name="robots" content="index,follow"/>
		<meta name="googlebot" content="index,follow"/>
		<meta name="description" content={description ? description : site.siteMetadata.description}/>
		<meta property="og:url" content={`${site.siteMetadata.siteURL}${pathname}`}/>
		<meta property="og:title" content={title}/>
		<meta property="og:description" content={description ? description : site.siteMetadata.description}/>
		<meta property="og:image" content={image}/>
		<meta property="og:image:alt" content={site.siteMetadata.title}/>
		<meta property="og:image:width" content="800"/>
		<meta property="og:image:height" content="600"/>
		<meta property="og:site_name" content={site.siteMetadata.title}/>
		</Helmet>
	)
}

SEO.defaultProps = {
	lang: `en`,
	meta: [],
	description: ``,
}

SEO.propTypes = {
	description: PropTypes.string,
	lang: PropTypes.string,
	meta: PropTypes.arrayOf(PropTypes.object),
	title: PropTypes.string.isRequired,
}

export default SEO
